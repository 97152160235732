<template>
  <b-overlay :show="showOverlay">
    <validation-observer ref="updateBlogContent" tag="div">
      <b-card class="data-edit-wrapper">
        <h2>Game Service Content</h2>
        <b-form v-if="blogContent" class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="blogContent.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Full Description"
              >

                <ckEditorMain ref="editorContent" :defaultContent="blogContent.description"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
            <b-col cols="3">
              <b-button
                  class="mt-2 ml-2"
                  variant="primary"
                  @click="updateBlogContent"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCarousel,
  BCarouselSlide,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { GetBlogContentBy, UpdateBlogContent } from '@/libs/Api/Blog'

export default {
  components: {
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ckEditorMain,
    BCard,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: 'edit-blog-content',
  title: 'edit blog content',
  data() {
    return {
      //service 1
      // gold 2
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      blogContent: null,
    }
  },
  async mounted() {
    await Promise.all([
      this.getBlogContents()
    ])
  },
  methods: {
    async getBlogContents() {
      let _this = this
      _this.showOverlay = true
      let getBlogContentBy = new GetBlogContentBy(_this)
      let data = {
        pageNumber: 1,
        count: 1,
        blogContentId: _this.$route.query.blogContentId
      }
      getBlogContentBy.setParams(data)
      await getBlogContentBy.fetch(function (content) {
        _this.blogContent = content.data.data[0]
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setEditorContent(content) {
      this.data.description = content
    },
    async updateBlogContent() {
      let _this = this
      Helper.validateForm(_this, 'updateBlogContent', async () => {
        _this.showOverlay = true
        let updateBlogContent = new UpdateBlogContent(_this)
        updateBlogContent.setParams({
          blogContentId: _this.$route.query.blogContentId
        })
        updateBlogContent.setPayload(_this.blogContent)
        await updateBlogContent.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.getBlogContents()
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

  }
}
</script>
